<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
    </nav>
    <router-view/>
  </div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		mounted() {
		},
		methods:{}
	}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
ul{
  list-style: none;
}
</style>
