<template>
	<div class="loginv">
		<login v-if="show" :buttonValue="buttonValue" @childEvent="speak" class="wow bounceIn"></login>
		<!-- 头部 -->
		<!-- <div class="hader">
      <div class="haderb">
        <div class="haderb top">
          <div class="haderb-top-left" style="padding-top: 72px">
            <img class="logoImg" src="../../src/img/logo.png" alt="" />
          </div>
          <div class="haderb-top-right">
            <button @click="handleButtonClick('tab1')">登录</button
            ><button @click="handleButtonClick('tab2')">注册</button>
          </div>
        </div>
        <div class="middle">
          <h4>"共筑绿色家园，盖亚设备，引领未来！"</h4>
          <p>"Build green homes, Gaia equipment, leading the future !"</p>
        </div>
      </div>
    </div> -->
		<div class="login-nav">
			<div class="nav-tit">
				<i class="el-icon-house"></i>
				<span>首页</span>
				<el-divider direction="vertical"></el-divider>
				<el-dropdown style="font-size: 16px" @command="handleCommand">
					<span class="el-dropdown-link">
						在线下单<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="a">钢网下单</el-dropdown-item>
						<el-dropdown-item command="b">治具下单</el-dropdown-item>
						<!-- <el-dropdown-item command="c">PCD</el-dropdown-item>
            <el-dropdown-item command="d">SMT</el-dropdown-item>
            <el-dropdown-item command="e">3D打印</el-dropdown-item> -->
					</el-dropdown-menu>
				</el-dropdown>
				<el-divider direction="vertical"></el-divider>
				<span>购物商城</span>
			</div>
		</div>
		<div class="login-nav-bar">
			<div class="top-nav-content">
				<img class="logoImg" src="../../src/img/logo2.png" alt="" />
				<!-- <div class="header-top-right" v-if="degnluTF"> -->
				<div class="header-top-right">
					<button @click="handleButtonClick('tab1')" v-if="degnluTF == true">
						登录
					</button>
					<button @click="gotoClick('tab1')" v-else>{{ this.bianMa }}</button>
					<button @click="handleButtonClick('tab2')" v-if="degnluTF == true">
						注册
					</button>
				</div>
				<!-- <div class="header-top-right" style="width: 230px;" v-else>
					<button @click="jumpHT('tab1')">进入下单平台</button>
				</div> -->
			</div>
		</div>

		<!-- 背景图 -->
		<!-- <div class="header">
      <div class="middle wow bounceIn">
        <h4>"共筑绿色家园，盖亚设备，引领未来！"</h4>
		<p>"Build green homes, Gaia equipment, leading the future !"</p> 
			
      </div>
    </div> -->
		<div class="bigBgcBox">
			<div class="bgcImg">
				<img src="../img/swiper/banner4.jpg" alt="" />
			</div>
		</div>

		<!-- 新型产品 -->
		<div class="product wow bounceIn">
			<div class="productb">
				<el-tabs v-model="activeName" class="tabBgc">
					<el-tab-pane name="first">
						<span slot="label">
							<div>
								<img src="../img/tab_2.png" alt="" style="width: 50px" />
							</div>
							激光钢网/治具
						</span>
						<div class="swiper-slide swiper-slide1">
							<img src="../img/swiper/banner2.jpg" class="swiper-img" />
						</div>
					</el-tab-pane>

					<el-tab-pane name="second">
						<span slot="label">
							<div>
								<img src="../img/tab_1.png" alt="" style="width: 50px" />
							</div>
							映上PCB
						</span>
						<div class="swiper-slide swiper-slide2">
							<img src="../img/swiper/banner1.jpg" class="swiper-img" />
						</div>
					</el-tab-pane>

					<el-tab-pane name="third">
						<span slot="label">
							<div>
								<img src="../img/tab_3.png" alt="" style="width: 50px" />
							</div>
							SMT贴片
						</span>
						<div class="swiper-slide swiper-slide2">
							<img src="../img/swiper/banner3.jpg" class="swiper-img" />
						</div>
					</el-tab-pane>
				</el-tabs>
				<!-- <div class="titleList new_products">
          <div class="strip"></div>
          <div class="smallTitle">新星产品</div>
        </div> -->
			</div>
		</div>

		<!-- 关于我们 -->
		<div class="aboutus">
			<!-- <div class="aboutusb">
                <p>关于我们</p>
                <div class="aboutusb-left">
                    <p>与信科技成立于2006年，是行业较早实现数字化转型的高新技术企业之一，专注于PCB打样/小批量、SMT贴片、激光钢网等领域，为全球电子行业企业、电子工程师、科研机构提供“价格优、品质高、交期快”的高性价比服务。
                        公司现有核心技术团队800余人、5大数字化生产基地，在中国香港、德国设有子公司，产品及服务遍及全球近180个国家和地区，拥有超350万用户。
                        2021年，与信科技打通了“EDA软件/PCB CAM软件 → PCB智造 → 元器件商城 → 激光钢网/治具 → SMT贴片”的全产业链各环节，致力于为客户带来更加高效、便捷的服务。</p>
                </div>
                <div class="aboutusb-right">
                    <img src="../img/gy.png" alt="">
                </div>
            </div> -->
			<div class="main-title wow bounceIn">
				<div class="title-content">客户晒单</div>
			</div>

			<dv-scroll-board :config="config" style="width: 500px; height: 220px; font-size: 20px" />
		</div>

		<!-- 企业文化 -->
		<div class="enterprise wow bounceIn">
			<div class="enterpriseb">
				<div class="titleList">
					<p class="strip"></p>
					<p class="smallTitle">企业文化</p>
				</div>
				<ul>
					<li @mouseover="showBigModal(0)" v-if="hover != 0" class="ena xiaotu1">
						<p>聪明&nbsp;/&nbsp;勤奋</p>
						<span></span>
					</li>
					<li :class="hover == 0 ? 'active' : ''" v-else class="active1">
						<p>专业</p>
						<div>
							<div></div>
						</div>
						<p>
							从PCB设计、信号仿真、制作加工（制板、焊接、物料、测试）技术服务（培训、派遣、开发），提供最专业最全面的一站式解决方案。
						</p>
					</li>

					<li @mouseover="showBigModal(1)" v-if="hover != 1" class="ena xiaotu2">
						<p>创新/进取</p>
						<span> <img src="../img/qy1.png" alt="" /></span>
					</li>
					<li :class="hover == 1 ? 'active' : ''" v-else class="active2">
						<p>实力</p>
						<div>
							<div></div>
						</div>
						<p>
							拥有自己的高端全自动化PCBA焊接贴装产线，可以支持0201，01005进行批量生产，BGA可以做到0.3MM
							Pitch，最多可以同时贴装400种物料，同时还拥有高端检测设备AOI
							ALD620，X-Ray检测设备，BGA维修设备。
						</p>
					</li>

					<li @mouseover="showBigModal(2)" v-if="hover != 2" class="ena xiaotu3">
						<p>效率/规范</p>
						<span><img src="../img/qy1.png" alt="" /></span>
					</li>
					<li :class="hover == 2 ? 'active' : ''" v-else class="active3">
						<p>领域</p>
						<div>
							<div></div>
						</div>
						<p>
							在计算机、通讯、工控、军工产品、安防、汽车电子、医疗设备、数码家电、图像处理、数据采集、逆变器、新能源等领域积累了丰富的经验。
						</p>
					</li>

					<li @mouseover="showBigModal(3)" v-if="hover != 3" class="ena xiaotu4">
						<p>自律/务实</p>
						<span><img src="../img/qy1.png" alt="" /></span>
					</li>
					<li :class="hover == 3 ? 'active' : ''" v-else class="active4">
						<p>品质</p>
						<div>
							<div></div>
						</div>
						<p>
							依靠完善的设计流程，严格的质量控制标准，
							<br />
							严格的质量控制标准，和优质快速的服务，
							<br />
							在价格、交期、质量方面具有充分的保证。
						</p>
					</li>

					<!-- 新增一个选项 -->
					<li @mouseover="showBigModal(4)" v-if="hover != 4" class="ena xiaotu5">
						<p>诚信/服务</p>
						<span><img src="../img/qy1.png" alt="" /></span>
					</li>
					<li :class="hover == 4 ? 'active' : ''" v-else class="active5">
						<p>服务</p>
						<div>
							<div></div>
						</div>
						<p>
							所有客户都提供专属企微客服，从下单到发货全流程贴身服。为客户提供可靠便捷快速的生产服务，赢得国内外客户认可和信赖。
						</p>
					</li>
					<!-- end -->
				</ul>
			</div>
		</div>

		<!-- 产品展示 -->
		<div class="product">
			<div class="productd">
				<p class="p1">产品展示</p>
				<div class="product_big_box">
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/11.jpg" alt="" />
						</div>
						<p>4层阻抗板</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/7.jpg" alt="" />
						</div>
						<p>6层阻抗板</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/8.jpg" alt="" />
						</div>
						<p>10层工控板</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/44.jpg" alt="" />
						</div>
						<p>HDI版</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/4.jpg" alt="" />
						</div>
						<p>厚铜板</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/6.jpg" alt="" />
						</div>
						<p>金手指</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/5.jpg" alt="" />
						</div>
						<p>双面高频板</p>
					</div>
					<div class="product_middle_box">
						<div class="product_small_box">
							<img src="../img/88.jpg" alt="" />
						</div>
						<p>铜基</p>
					</div>
				</div>
			</div>
		</div>

		<!-- 联系我们 -->
		<!-- <div class="contactus wow bounceIn"> -->
		<div class="contactus">
			<div class="contactusd">
				<p style="
            padding-left: 50px;
            box-sizing: border;
            color: rgba(26, 132, 179);
          ">
					联系我们
				</p>
				<div class="contactusd-left"></div>
				<div class="contactusd-right">
					<ul>
						<li>
							<h4>武汉盖亚设备制造有限公司</h4>
						</li>
						<li>
							<p>地址：</p>
							<p>武汉市江夏区经济开发区一新完美工业园</p>
						</li>
						<li>电话：+86 15071201551</li>
						<li>邮箱：gaiyazhizao@163.com</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	// @ is an alias to /src
	import login from "@/components/login/login.vue";
	import {
		WOW
	} from "wowjs";

	import Swiper from "swiper"; // 注意引入的是Swiper
	import "swiper/css/swiper.min.css"; // 注意这里的引入

	export default {
		name: "loginView",
		components: {
			login,
		},
		data() {
			return {
				config: {
					data: [
						["李先生", "锡膏网", "不需要", "166****5213", "河南省新乡市"],
						["陈先生", "红胶网", "mark点通孔", "158****4123", "安徽省阜阳市"],
						["黄女士", "锡膏网", "mark点半刻", "178****0223", "福建省三明市"],
						["王先生", "锡膏网", "mark点半刻", "155****4851", "河北省邯郸市"],
						["张先生", "红胶网", "mark点通孔", "178****7425", "湖北省十堰市"],
						["李先生", "锡膏网", "mark点通孔", "150****4529", "湖南省衡阳市"],
						["赵女士", "红胶网", "mark点半刻", "139****9584", "湖北省鄂州市"],
						["钱先生", "红胶网", "不需要", "136****8524", "贵州省安顺市"],
						["李先生", "锡膏网", "mark点半刻", "165****6521", "广东省梅州市"],
						["王女士", "锡膏网", "不需要", "177****8524", "江西省鹰潭市"],
					],
					waitTime: 1000,
					header: ["姓名", "钢网用途", "定位mark点", "联系电话", "地址"],
					headerBGC: "#a0dff8",
					headerHeight: 72,
					oddRowBGC: "#c7cacf",
					evenRowBGC: "#8e9195",
				},
				hover: 0,
				buttonValue: "",
				show: false,
				degnluTF: true,
				bianMa: "",
				activeName: "first",
			};
		},
		mounted() {
			if (localStorage.getItem("token")) {
				this.degnluTF = false;
			} else {
				this.degnluTF = true;
			}
			new WOW().init();
			var mySwiper = new Swiper(".swiper-container", {
				// config...
				loop: true,
				autoplay: true,
			});
			this.getXinxi();
		},
		methods: {
			jumpHT() {
				this.$router.push({
					path: "/bindex",
				});
			},
			speak(data) {
				this.show = data;
			},
			showBigModal(i) {
				this.hover = i;
			},
			handleButtonClick(index) {
				this.show = true;
				this.buttonValue = index;
			},
			gotoClick(index) {
				this.$router.push("/index");
			},
			bodyScale() {
				var deviceWidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
				var scale = deviceWidth / 1920; // 分母——设计稿的尺寸
				document.documentElement.style.zoom = scale; //放大缩小相应倍数
			},
			handleCommand(command) {
				if (!this.degnluTF) {
					if (command == "a") {
						this.$router.push({
							path: "/xiadan",
						});
					} else if (command == "b") {
						this.$router.push({
							path: "/zhiJuxiaDan",
						});
					} else if (command == "c") {
						this.$router.push({
							path: "/pcbIndex",
						});
					} else if (command == "d") {
						this.$router.push({
							path: "/smtIndex",
						});
					} else if (command == "e") {
						this.$router.push({
							path: "/printIndex",
						});
					}
				} else {
					this.show = true;
					this.buttonValue = "tab1";
				}
			},
			//获取用户名
			getXinxi() {
				this.$http
					.post(
						"content/usermsg", {
							a: 1,
						}, {
							headers: {
								token: localStorage.getItem("token"),
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then((response) => {
						if (response != 0) {
							this.bianMa = response.data.data.loginname;
						}

					});
			},
		},
		created() {
			window.addEventListener("resize", this.bodyScale, false);
		},
	};
</script>
<style lang="scss" scoped>
	// .logoImg {
	//   margin-top: 12px;
	//   width: 180px;
	//   height: 90px;
	// }
	.el-dropdown-link {
		cursor: pointer;
	}

	.swiper-wrapper {
		//   width: 1400px !important;
		//   height: 370px !important;
	}

	.dv-scroll-board {
		width: 1450px !important;
		height: 500px !important;
		margin: auto !important;
		margin-top: 50px !important;
	}

	.loginv {
		width: 100%;
	}

	/* 头部 */
	.login-nav {
		width: 100%;
		height: 40px;
		line-height: 40px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		background-color: #fff;

		/* border-bottom: 1px solid #000 !important; */
		.nav-tit {
			width: 1440px;
			margin: 0 auto;
			text-align: left;
			height: 100%;

			.el-divider {
				background-color: #ccc;
				// z-index: 9999;
			}
		}
	}

	.bigBgcBox {
		width: 100%;
		margin-top: 105px;
		margin-bottom: 50px;

		.bgcImg img {
			width: 100%;
			height: 100%;
		}
	}

	.login-nav-bar {
		width: 100%;
		height: 66px;
		// box-shadow: 0px 5px 5px;
		position: fixed;
		top: 40px;
		left: 0;
		z-index: 10;
		background-color: #fff;
		border-top: 1px solid #f4f2f2;

		.top-nav-content {
			width: 1440px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.logoImg {
				height: 100px;
				width: 180px;
			}
		}
	}

	.haderb {
		width: 1440px;
		height: 100%;
		margin: auto;
		background-color: #4e6ef0;
	}

	/* 重写头部 */
	.swiper-wrapper {
		width: 1400px;
		height: 370px;
		overflow: hidden;
		display: flex;

		.swiper-slide {
			width: 100%;

			img {
				width: 1400px;
				height: 370px;
			}
		}
	}

	/* ending */

	div[class="haderb top"] {
		height: 72px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.haderb-top-left {
		font-size: 39px;
		font-family: DIN-Medium, DIN;
		font-weight: 500;
		color: #ffffff;
	}

	.header-top-right {
		display: flex;
		justify-content: space-around;
		width: 200px;
	}

	.header-top-right button {
		width: 87px;
		height: 38px;
		border-radius: 4px;
		cursor: pointer;
		background-color: transparent;
		border: none;
		border: 1px solid #5981ff !important;
		color: #5981ff;
	}

	.header-top-right button:first-child {
		color: #fff;
		background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
		border-radius: 4px;
	}

	.middle {
		text-align: left;
		padding-left: 270px;
		color: #fff;
	}

	.middle h4 {
		font-size: 46px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		width: 918px;
	}

	.middle p {
		margin-top: 13px;
		margin-left: 13px;
	}

	/* 新型产品 */
	.product {
		width: 100%;
		height: 752px;
		background: #f7f8fa;
	}

	.productb {
		width: 1440px;
		height: 100%;
		margin: auto;
		overflow: hidden;
	}

	.productb .el-tabs__nav {
		margin: bottom 16px;
	}

	/* .productb p {
  text-align: left;
  font-size: 38px;
  font-weight: 500;
  margin-top: 111px;
  position: relative;
} */

	/* .product-left::after {
  position: absolute;
  content: "";
  width: 48px;
  height: 8px;
  top: -110px;
  left: 0;
  background-color: blue;
} */

	.product-left {
		width: 1440px;
		height: 370px;
		margin-top: 124px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hold {
		width: 1440px;
		height: 370px;

		//   background-color: #4e6ef0;
		.swiper-container {
			z-index: 999;
			width: 1440px;
			height: 370px;

			.swiper-wrapper {
				overflow: initial;
			}

			.swiper-slide {
				z-index: 999;
				width: 1440px;
				height: 370px;
				width: 100%;
				height: 100%;
			}

			.swiper-slide2 {
				width: 1440px;
				height: 370px;
			}

			.swiper-img {
				width: 1440px;
				height: 370px;
			}
		}
	}

	.product-left img {
		width: 100%;
		height: 100%;
	}

	.product-right {
		width: 1117px;
		height: 425px;
		background-color: #fff;
		float: right;
		margin-top: 63px;
	}

	.product-right ul {
		margin-top: 99px;
		padding-left: 205px;
	}

	.product-right ul li {
		text-align: left;
		margin-bottom: 30px;
		margin-left: 55px;
	}

	.product-right ul li:nth-child(2) {
		display: flex;
		font-size: 20px;
		padding-left: 10px;
	}

	.product-right ul li:nth-child(2) p {
		color: blue;
		margin: 0 !important;
		margin-right: 20px !important;
		font-size: 22px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #4e6ef0;
	}

	.product-right ul li:first-child {
		font-size: 28px;
		font-weight: 500;
		color: #222222;
	}

	.product-right ul li:nth-child(3) {
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
		width: 519px;
	}

	.product-right ul li:nth-child(4) {
		width: 160px !important;
		height: 42px;
		background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
		border-radius: 4px;
		margin-top: 24px;
		display: flex;
	}

	.product-right ul li:nth-child(4) p {
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		line-height: 41px;
		text-align: left;
		margin-left: 18px !important;
		margin: 0;
	}

	.el-icon-right {
		color: #fff;
		margin-top: 13px;
		margin-left: 33px;
	}

	/* 关于我们 */
	.aboutus {
		width: 100%;
		height: 540px;
		margin-top: -80px;
		padding-top: 30px;
		background-color: #fff;

		.main-title {
			.title-content {
				margin: 0 auto;
				margin-top: 50px;
				width: 1440px;
				padding-left: 28px;
				text-align: left;
				font-size: 30px;
				font-weight: 600;
				position: relative;
				font-size: 38px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: rgba(26, 132, 179);
			}
		}

		.title-content::after {
			position: absolute;
			content: "";
			margin-top: 5px;
			left: 0;
			width: 8px;
			height: 46px;
			background-color: rgba(26, 132, 179);
		}
	}

	.aboutusb {
		width: 1440px;
		height: 100%;
		margin: auto;
	}

	.aboutusb p {
		text-align: left;
		font-size: 30px;
		font-weight: 600;
		margin-top: 111px;
		position: relative;
		font-size: 38px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
	}

	.aboutusb-left {
		float: left;
		width: 519px;
		height: 360px;
		position: relative;
		margin-top: 48px;
	}

	.aboutusb-left ::after {
		position: absolute;
		content: "";
		top: -35px;
		left: 0;
		width: 48px;
		height: 8px;
		background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
	}

	.aboutusb-left p {
		font-size: 22px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		letter-spacing: 2px;
		margin: 0 !important;
	}

	.aboutusb-right {
		width: 716px;
		float: right;
		height: 498px;
		-webkit-perspective: 150;
		margin-top: -13px;
		margin-right: 135px;
	}

	.aboutusb-right img {
		width: 100%;
		height: 100%;
		-webkit-transform: rotateY(-1deg);
		transform-origin: left bottom;
	}

	/* 企业文化 */
	.enterprise {
		width: 100%;
		/* height: 752px; */
		padding-bottom: 50px;
		/* background: #f7f8fa; */
		background-color: #fff;
	}

	.enterpriseb {
		width: 1440px;
		height: 100%;
		margin: auto;
		overflow: hidden;
	}

	.smallTitle {
		text-align: left;
		font-size: 38px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: rgba(26, 132, 179);
		/* margin-top: 111px; */
		position: relative;
	}

	.titleList {
		display: flex;
		margin-top: 171px;
		margin-bottom: -40px;
	}

	.new_products {
		margin: 100px 0 -80px 0;
	}

	.strip {
		background-color: rgba(26, 132, 179);
		width: 10px;
		height: 46px;
		margin-top: 5px;
		margin-right: 28px;
	}

	/* .enterpriseb::after {
  position: absolute;
  content: "";
  width: 48px;
  height: 8px;
  margin-left: -719px;
  margin-top: -449px;
  background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
} */

	.enterpriseb ul {
		margin-top: 74px;
		display: flex;
		position: relative;
	}

	.enterpriseb ul li {
		width: 221px;
		height: 383px;
		background: #ffffff;
		box-shadow: 0px 2px 16px 0px rgba(78, 110, 240, 0.3);
		border-radius: 6px;
		margin-right: 25px;
	}

	.ena p {
		height: 33px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
		margin-top: 53px;
		margin-left: 56px;
	}

	.enterpriseb ul li span {
		display: block;
		width: 54px !important;
		height: 54px !important;
		border-radius: 50%;
		background-color: #6f86e4;
		margin-left: 83px;
		margin-top: 205px;
		opacity: 0.3;
	}

	.enterpriseb ul li span img {
		width: 100%;
		height: 100%;
	}

	.el-tabs__nav-wrap::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		/* height: 2px; */
		background-color: #e4e7ed;
		z-index: 1;
	}

	.active {
		width: 500px !important;
		height: 383px;
		color: #fff;
		border-radius: 8px !important;
	}

	.xiaotu1 {
		background-image: url("../img/company/专业-1.jpg") !important;
	}

	.xiaotu2 {
		background-image: url("../img/company/实力-1.jpg") !important;
	}

	.xiaotu3 {
		background-image: url("../img/company/领域-1.jpg") !important;
	}

	.xiaotu4 {
		background-image: url("../img/company/品质-1.jpg") !important;
	}

	.xiaotu5 {
		background-image: url("../img/company/服务-1.jpg") !important;
	}

	.active1 {
		background-image: url("../img/company/专业.jpg") !important;
	}

	.active2 {
		background-image: url("../img/company/实力.jpg") !important;
	}

	.active3 {
		background-image: url("../img/company/领域.jpg") !important;
	}

	.active4 {
		background-image: url("../img/company/品质.jpg") !important;
	}

	.active5 {
		background-image: url("../img/company/服务.jpg") !important;
	}

	.active p:first-child {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin-left: 52px;
		margin-top: 53px !important;
		width: 48px;
		height: 33px;
		text-shadow: 1px 1px 1px #082dff;
	}

	.active div {
		width: 381px;
		height: 3px;
		background: #ffffff;
		opacity: 0.2;
		background-color: rgba(250, 250, 250, 0.2);
		margin-left: 52px;
		margin-top: 8px;
	}

	.active div div {
		width: 40px;
		height: 3px;
		background: #ffffff;
		opacity: 1 !important;
		margin-left: 0 !important;
		box-shadow: 1px 1px 1px #000;
	}

	.active p:nth-of-type(2) {
		width: 385px;
		height: 75px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 35px;
		margin-top: 46px !important;
		margin-left: 48px !important;
		text-shadow: 1px 1px 1px #082dff;
	}

	// 产品展示
	.product {
		width: 100%;
		height: 600px;
		background-color: #fff;
		margin-top: -90px;

		.productd {
			width: 1440px;
			height: 100%;
			margin: auto;

			.p1 {
				text-align: left;
				font-size: 38px;
				font-weight: 500;
				margin-top: 111px;
				position: relative;
				color: rgba(26, 132, 179);
				padding-left: 35px;
				margin: 100px 0 35px 0;
			}

			.container {
				display: flex;
				flex-wrap: wrap;
			}

			.box {
				width: 340px;
				// flex-basis: 25%;
				height: 200px;
				background-color: #0f0;
				margin-right: 20px;
				margin-bottom: 20px;
			}
		}
	}

	.product_big_box {
		display: flex;
		flex-wrap: wrap;

		.product_middle_box {
			margin: 0px 0px 50px 0;
			width: 25%;

			.product_small_box {
				width: 320px;
				height: 200px;

				/* background-color: rgb(82, 190, 9); */
				img {
					width: 320px;
					height: 200px;
				}
			}

			p {
				margin-top: 10px;
			}
		}
	}

	.productd .p1::after {
		/* position: absolute;
		content: "";
		width: 8px;
		height: 48px;
		background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
		left: 697px;
		top: 0px; */

		position: absolute;
		content: "";
		width: 8px;
		height: 46px;
		margin-top: 5px;
		margin-left: -188px;
		background-color: rgba(26, 132, 179);
	}

	/* 联系我们 */
	.contactus {
		width: 100%;
		height: 485px;
		margin-top: -70px;
	}

	.contactusd {
		width: 1440px;
		height: 100%;
		margin: auto;
	}

	.contactusd p {
		/* text-align: center;

		margin-left: -156px;
		margin-top: 100px;
		position: relative;
		font-size: 38px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222; */

		text-align: left;
		font-size: 38px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
		margin-top: 111px;
		position: relative;
	}

	.contactusd p::after {
		/* position: absolute;
		content: "";
		width: 8px;
		height: 48px;
		background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
		left: 697px;
		top: 0px; */

		position: absolute;
		content: "";
		width: 10px;
		height: 44px;
		margin-left: -188px;
		margin-top: 5px;
		background-color: rgba(26, 132, 179);
	}

	.contactusd-left {
		margin-left: 15px;
		margin-top: 51px;
		width: 620px;
		height: 300px;
		float: left;
		background-image: url("../img/dt.png");
		background-size: cover;
		/* 图像铺满元素的宽高 */
		background-position: center;
		/* 图像在元素中居中显示 */
	}

	.contactusd-right {
		/* width: 450px; */
		height: 250px;
		/* background-color:red; */
		float: right;
		margin-top: 73px;
		margin-right: 180px;
	}

	.tabBgc {
		margin-top: 100px;
	}

	.contactusd-right ul li p {
		position: static !important;
		margin: 0 !important;
	}

	.contactusd-right ul li p::after {
		display: none;
	}

	.contactusd-right ul li {
		text-align: left;
		font-size: 22px;
		margin-bottom: 25px;
	}

	.contactusd-right ul li:first-child {
		margin-top: 10px;
	}

	.contactusd-right ul li:first-child p {
		width: 240px;
		height: 33px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
		line-height: 33px;
	}

	.contactusd-right ul li:nth-child(2) {
		display: flex;
	}

	.contactusd-right ul li:nth-child(2) p {
		font-size: 22px;
		font-weight: 400;
		position: static !important;
		margin-top: 10px !important;
	}

	.contactusd-right ul li:nth-child(2) p:first-child {
		/* width: 95px; */
	}

	.contactusd-right ul li:nth-child(2) p:nth-child(2) {
		width: 448px;
	}
</style>