<template>
	<div class="index">
		<el-container>
			<el-aside>
				<div class="logo" @click="ceshi">
					<!-- <img class="logoImg" src="../../src/img/logo.png" alt=""> -->
					<img class="logoImg" src="../../src/img/logo2.png" alt="" />
				</div>
				<!-- 侧边栏 -->
				<el-menu default-active="-1" class="text-align-left" @open="handleOpen" @close="handleClose"
					v-for="(value, aindex) in shouye" :key="aindex">
					<el-menu-item :index="aindex + '1'" v-if="value.name == '首页'" @click="tiaozhuan(value.id)">
						<i :class="value.icon"></i>
						<span slot="title" style="font-size: 18px">{{ value.name }}</span>
					</el-menu-item>

					<el-submenu :index="aindex + ''" v-else>
						<i :class="value.icon"></i>
						<span slot="title" style="font-size: 18px">{{ value.name }}</span>

						<!-- <el-menu-item :index="value - value1.id" v-for="(value1, bindex) in value.children" :key="bindex" @click="tiaozhuan(value1.id)"> -->
						<el-menu-item :index="bindex + ''" v-for="(value1, bindex) in value.children" :key="bindex"
							@click="tiaozhuan(value1.id)">
							<div style="margin-left: 29px">{{ value1.name }}</div>
							<div class="dian" v-show="show1 == 1"></div>
						</el-menu-item>
					</el-submenu>

					<!-- <div @click="toshouye(aindex)" slot="title"> -->
					<!-- <i class="el-icon-s-grid" v-if="aindx!=0"></i> -->
					<!-- <span slot="title" style="font-size: 18px;">{{ value.name }}</span> -->
					<!-- </div> -->
					<!-- <div>
                    <el-menu-item index="1-1" v-for="(value, bindex) in value.children" :key="bindex"
                      @click="tiaozhuan(value.id)">
                      <div style="margin-left: 29px;">{{ value.name }}</div>
                      <div class="dian" v-show="show1 == 1"></div>
                    </el-menu-item>
                </div> -->
					<!-- </el-submenu> -->
				</el-menu>
				<!-- <div style="margin-bottom: 20px;" @click="zhiJuxiaDan">治具下单</div> -->
				<!-- <div @click="zhiJudingDan">治具订单</div> -->
				<!-- <div @click="jumpPage(1)"
					style="margin-top: 20px;text-align: left;padding-left: 30px;box-sizing: border-box;">SMT订单</div> -->
				<!-- <div @click="jumpPage(2)"
					style="margin-top: 20px;text-align: left;padding-left: 30px;box-sizing: border-box;">PBC元器件</div> -->
				<!-- <div @click="jumpPage(3)"
					style="margin-top: 20px;text-align: left;padding-left: 30px;box-sizing: border-box;">SMT元器件</div> -->
				<!-- <div @click="jumpPage(4)"
					style="margin-top: 20px;text-align: left;padding-left: 30px;box-sizing: border-box;">3D打印</div> -->
				<!-- <div @click="jumpPage(5)"
					style="margin-top: 20px;text-align: left;padding-left: 30px;box-sizing: border-box;">CNC加工</div> -->
			</el-aside>
			<el-container>
				<!-- 头部 -->
				<el-header>
					<!-- 面包屑 -->
					<el-breadcrumb separator="/">
						<el-breadcrumb-item style="">
							<!-- <span style="color: #2f56ee; font-size: 20px; cursor: pointer" @click="goshouye">
								首页
							</span> -->
						</el-breadcrumb-item>
					</el-breadcrumb>
					<div class="user">
						<el-badge :value="9" class="item">
							<i class="el-icon-bell"></i>
						</el-badge>
						<div class="photo">
							<el-avatar icon="el-icon-user-solid" :size="34"></el-avatar>
							<span>{{ this.bianMa }}</span>
						</div>
						<el-button type="text" @click="open"><i class="el-icon-s-operation"></i></el-button>
					</div>
				</el-header>

				<!-- 内容 -->
				<el-main style="background-color: #eaecf2">
					<!-- 二级路由 -->
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				home: "",
				show1: 0,
				shouye: [],
				bianMa: "",
			};
		},
		mounted() {
			this.getshouye(), this.getXinxi();
		},
		methods: {
			// 测试
			ceshi() {
				this.$router.push("/");
			},
			goshouye() {
				/* this.$router.push({
					path: "/bindex"
				}); */

				this.$router.push("/");

				/* this.$http
					.post(
						"content/loginout", {
							a: 1
						}, {
							headers: {
								token: localStorage.getItem("token"),
							},
						}
					)
					.then((response) => {
						this.$router.push("/");
					}); */
			},
			zhiJuxiaDan() {
				this.$router.push({
					path: "/zhiJuxiaDan",
				});
			},
			zhiJudingDan() {},
			//获取用户名
			getXinxi() {
				this.$http
					.post(
						"content/usermsg", {
							a: 1,
						}, {
							headers: {
								token: localStorage.getItem("token"),
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then((response) => {
						if (response != 1) {
							this.bianMa = response.data.data.loginname;
						}
					});
			},
			toshouye(aindex) {
				if (aindex == 0) {
					this.$router.push({
						path: "/bindex",
					});
				}
			},
			//退出
			open() {
				this.$confirm("此操作将退出登录, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$http
							.post(
								"content/loginout", {
									a: 1,
								}, {
									headers: {
										token: localStorage.getItem("token"),
									},
								}
							)
							.then((response) => {
								localStorage.removeItem("token");
								this.$router.push("/");
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消",
						});
					});
			},
			tiaozhuan(num) {
				if (num == 1) {
					this.$router.push({
						path: "/bindex",
					});
				} else if (num == 4) {
					this.$router.push({
						path: "/xiadan",
					});
				} else if (num == 5) {
					this.$router.push({
						path: "/yuding",
					});
				} else if (num == 9) {
					this.$router.push({
						path: "/index/datum",
					});
				} else if (num == 10) {
					this.$router.push({
						path: "/index/password",
					});
				} else if (num == 11) {
					this.$router.push({
						path: "/index/cellphone",
					});
				} else if (num == 12) {
					this.$router.push({
						path: "/index/wechatBinding",
					});
				} else if (num == 13) {
					this.$router.push({
						path: "/index/news",
					});
				} else if (num == 14) {
					this.$router.push({
						path: "/index/balance",
					});
				} else if (num == 15) {
					this.$router.push({
						path: "/zhiJuxiaDan",
					});
				} else if (num == 16) {
					this.$router.push({
						path: "/fixtureOrder",
					});
				} else if (num == 17) {
					this.$router.push({
						path: "/pcbIndex",
					});
				} else if (num == 18) {
					this.$router.push({
						path: "/smtIndex",
					});
				} else if (num == 19) {
					this.$router.push({
						path: "/printIndex",
					});
				} else if (num == 20) {
					this.$router.push({
						path: "/cncIndex",
					});
				} else if (num == 21) {
					this.$router.push({
						path: "/pcbIndex",
					});
				} else if (num == 22) {
					this.$router.push({
						path: "/smtIndex",
					});
				} else if (num == 23) {
					this.$router.push({
						path: "/printIndex",
					});
				} else if (num == 24) {
					this.$router.push({
						path: "/cncIndex",
					});
				}
			},

			jumpPage(e) {
				if (e == 1) {
					this.$router.push({
						path: "/fixtureOrder",
					});
				} else if (e == 2) {
					this.$router.push({
						path: "/pcbIndex",
					});
				} else if (e == 3) {
					this.$router.push({
						path: "/smtIndex",
					});
				} else if (e == 4) {
					this.$router.push({
						path: "/printIndex",
					});
				} else if (e == 5) {
					this.$router.push({
						path: "/cncIndex",
					});
				}
			},

			getshouye() {
				this.$http
					.post(
						"content/getmenu", {
							a: 1,
						}, {
							headers: {
								token: localStorage.getItem("token"),
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then((response) => {
						// this.$router.push('/index');
						// 处理成功的响应数据xn
						this.shouye = response.data.data;
					})
					.catch((error) => {
						// console.error(error);
						// 处理错误
					});
			},
			show(index) {
				this.show1 = index;
			},
			handleOpen(key, keyPath) {
			},
			handleClose(key, keyPath) {
			},
		},
	};
</script>

<style scoped lang="scss">
	.index {
		width: 100%;
	}

	.el-aside {
		width: 280px !important;
		// height: 100vh !important;
	}

	.logo {
		width: 280px;
		height: 86px;

		line-height: 86px;
		font-size: 36px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		cursor: pointer;

		.logoImg {
			width: 180px;
			height: 105px;
		}
	}

	.home {
		padding-left: 23px;
		width: 280px;
		height: 53px;
		text-align: left;
		line-height: 53px;
		box-sizing: border-box;
		border-radius: 12px;
		color: #2f56ee;
		font-size: 19px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 600;
		position: relative;
		cursor: pointer;
		// opacity: 0.08;
	}

	.yanse {
		background: linear-gradient(90deg,
				rgba(47, 86, 238, 0.08) 0%,
				rgba(47, 86, 238, 0.08) 100%);
	}

	.home span {
		margin-left: 9px;
	}

	.home div {
		width: 10px;
		height: 10px;
		background: #52d869;
		border-radius: 50%;
		position: absolute;
		top: 21px;
		right: 22px;
	}

	// 侧边栏
	.el-aside {
		width: 280px;
		background: #ffffff;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
		z-index: 1;
	}

	.el-menu {
		border: none !important;
	}

	.el-menu-item {
		// background-color: red;
		padding-left: 23px !important;
		margin-top: 8px;
		width: 100% !important;
		box-sizing: border-box;
		font-size: 18px;
	}

	.text-align-left {
		text-align: left;
	}

	.dian {
		width: 10px;
		height: 10px;
		background: #52d869;
		border-radius: 50%;
		position: absolute;
		top: 21px;
		right: 22px;
	}

	// 头部
	.el-header {
		height: 64px !important;
		background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		justify-content: space-between;
	}

	// 面包屑
	.el-breadcrumb {
		height: 64px;
		line-height: 64px;
	}

	.el-breadcrumb__inner {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
	}

	.el-breadcrumb__inner {
		color: #2f56ee !important;
	}

	.user {
		height: 100%;
		// width: 100px;
		display: flex;
		align-items: center;
	}

	.el-icon-bell {
		font-size: 16px;
	}

	.item {
		margin-top: 10px;
		margin-right: 40px;
		// width: 20px !important;
		// height: 20px !important;
	}

	.photo {
		display: flex;
		align-items: center;
	}

	.photo span {
		width: 48px;
		height: 22px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #0a112b;
		line-height: 22px;
		display: block;
		margin-right: 15px;
	}

	.el-icon-s-operation {
		margin-left: 36px;
		font-size: 23px;
	}
</style>