import Vue from 'vue'
import VueRouter from 'vue-router'
import loginViewVue from '@/views/loginView.vue'
import indexViewVue from '@/views/indexView.vue';

Vue.use(VueRouter)

// 针对ElementUI导航栏中重复导航报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'login',
		component: () => import("@/views/loginView.vue"),
		meta: {
			title: '登录'
		},
	},
	//微信扫码登陆后跳到手机绑定页面
	{
		path: '/pages/client/register',
		component: () => import('@/pages/client/register.vue'),
		meta: {
			title: '微信扫码登陆后跳到手机绑定页面'
		}
	},
	{
		path: '/index',
		name: 'index',
		component: () => import("@/views/indexView.vue"),
		meta: {
			title: '首页'
		},

		redirect: {
			name: 'bindex'
		},
		children: [
			// 小首页
			{
				path: '/bindex',
				name: 'bindex',
				component: () =>
					import('@/pages/IndexView.vue'),
				meta: {
					title: '小首页'
				}
			},
			// 钢网下单
			{
				path: '/xiadan',
				component: () =>
					import('@/pages/other/other.vue'),
				meta: {
					title: '下单'
				}
			},
			// 预定下单
			{
				path: '/yuding',
				component: () =>
					import('@/pages/other/reserve.vue'),
				meta: {
					title: '预定下单'
				}
			},
			//治具下单
			{
				path: '/zhiJuxiaDan',
				component: () =>
					import('@/pages/equipment/zhiJuxiaDan.vue'),
				meta: {
					title: '预定下单'
				}
			},
			//治具下单内容
			{
				path: '/zhiJuContent',
				name:"/zhiJuContent",
				component: () =>
					import('@/pages/equipment/zhijuContent.vue'),
				meta: {
					title: '预定下单'
				}
			},
			//治具订单
			{
				path: '/fixtureOrder',
				component: () =>
					import('@/pages/equipment/fixtureOrder.vue'),
				meta: {
					title: '预定下单'
				}
			},
			//pcb元器件
			{
				path: '/pcbIndex',
				component: () =>
					import('@/pages/equipment/pcbIndex.vue'),
				meta: {
					title: 'PCB元器件'
				}
			},
			//pcb元器件
			{
				path: '/smtIndex',
				component: () =>
					import('@/pages/equipment/smtIndex.vue'),
				meta: {
					title: 'SMT元器件'
				}
			},
			//3D打印
			{
				path: '/printIndex',
				component: () =>
					import('@/pages/equipment/printIndex.vue'),
				meta: {
					title: '3D打印'
				}
			},
			//CNC加工
			{
				path: '/cncIndex',
				component: () =>
					import('@/pages/equipment/cncIndex.vue'),
				meta: {
					title: 'CNC原件'
				}
			},
			// 客户资料
			{
				path: 'datum',
				component: () =>
					import("@/pages/client/datum.vue"),
				meta: {
					title: '资料归属'
				}
			},
			// 登录密码
			{
				path: '/index/password',
				component: () =>
					import('@/pages/client/password.vue'),
				meta: {
					title: '登录密码'
				}
			},
			// 绑定手机
			{
				path: 'cellphone',
				component: () =>
					import('@/pages/client/cellphone.vue'),
				meta: {
					title: '绑定手机'
				}
			},
			// 微信绑定
			{
				path: 'wechatBinding',
				component: () =>
					import('@/pages/client/wechatBinding.vue'),
				meta: {
					title: '微信绑定'
				}
			},
			// 消息通知
			{
				path: 'news',
				component: () =>
					import('@/pages/client/news'),
				meta: {
					title: '消息通知'
				}
			},
			{
				path: 'balance',
				component: () =>
					import('@/pages/client/balance'),
				meta: {
					title: '消息通知'
				}
			},

		]
	},

]

const router = new VueRouter({
	routes
})

export default router