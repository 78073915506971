<template>
  <div class="login">
    <div class="loginb">
      <div class="loginb-right">
        <div style="margin-left: 450px; cursor: pointer" @click="closeLogin">
          X
        </div>
        <div class="tabs">
          <div
            class="tab"
            :class="{ active: activeTab === 'tab1' }"
            @click="changeTab('tab1')"
          >
            登录
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === 'tab2' }"
            @click="changeTab('tab2')"
          >
            注册
          </div>
        </div>
        <div class="tab-content" v-show="showtab">
          <!-- 账号登录 -->
          <div class="tab-pane" v-show="activeTab === 'tab1'">
            <ul>
              <li>
                <p>手机号</p>
              </li>
              <li><input type="text" v-model="phoneNumber" /></li>
              <li>
                <p>密码</p>
              </li>
              <li><input type="password" v-model="password1" /></li>
              <li><button @click="login1(1)">登录</button></li>
            </ul>
          </div>
          <!-- 注册 -->

          <div class="tab-pane" v-show="activeTab === 'tab2'">
            <ul>
              <li>
                <p>手机号</p>
              </li>
              <li><input type="text" v-model="phoneNumber" /></li>
              <li>
                <p>验证码</p>
              </li>
              <li class="in">
                <input type="text" id="in" v-model="verificationCode" />
                <p @click="getCode(1)">获取验证码</p>
              </li>
              <li><button @click="register">注册</button></li>
            </ul>
          </div>
        </div>
        <!-- 二维码 -->
        <div class="welogin" v-show="qrcode">
          <iframe :src="img" frameborder="0" width="100px"></iframe>
        </div>
        <!-- 手机登录-->
        <div class="tab-pane" v-show="verification">
          <ul>
            <li>
              <p>手机号</p>
            </li>
            <li><input type="text" v-model="phoneNumber" /></li>
            <li>
              <p>验证码</p>
            </li>
            <li class="in">
              <input type="text" id="in" v-model="verificationCode" />
              <p @click="getCode(2)">获取验证码</p>
            </li>
            <li><button @click="login1(2)">登录</button></li>
          </ul>
        </div>
        <ul class="bottomul">
          <li></li>
          <p>其他登录方式</p>
          <li></li>
        </ul>
        <div class="loginb-bottom">
          <div @click="weLogin(0)">
            <img src="../../img/wx.png" />
            <p>微信登录</p>
          </div>
          <div @click="weLogin(1)">
            <img src="../../img/sj.png" alt="" />
            <p>账号登录</p>
          </div>
          <div @click="weLogin(2)">
            <img src="../../img/sj.png" alt="" />
            <p>手机登录</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { api } from "@/api/api";
export default {
  name: "login",
  data() {
    return {
      activeTab: "tab1",
      showtab: true,
      // phoneNumber:'',
      // phoneNumber: "16638505213",
      phoneNumber: "",
      //登录手机号
      // password1: "621799",
      password1: "",
      //登录密码
      // password1:'',
      verificationCode: "", //注册验证码
      verificationCode: "", //登录验证码
      imageUrl: "../../img/wx.png",
      img: "",
      qrcode: false, //二维码
      verification: false, //验证码
      token: "",
    };
  },
  props: {
    buttonValue: {
      type: String,
      required: true,
    },
  },
  created() {
    // 在created钩子函数中重新赋值activeTab
    this.activeTab = this.buttonValue;
    this.fetchData();
  },
  methods: {
    //关闭登录页面
    closeLogin() {
      this.$emit("childEvent", false);
    },
    // tab栏
    changeTab(tab) {
      if (tab == "tab2") {
        this.showtab = true;
        this.qrcode = false;
        this.verification = false;
      }
      this.activeTab = tab;
    },
    weLogin(i) {
      console.log(i);
      if (i == 0) {
        this.showtab = false;
        this.qrcode = true;
        this.verification = false;
        this.activeTab = "tab1";
        this.imageUrl = "../../img/wxy.png";
      } else if (i == 1) {
        this.showtab = true;
        this.qrcode = false;
        this.verification = false;
        this.activeTab = "tab1";
      } else {
        this.verification = true;
        this.showtab = false;
        this.qrcode = false;
        this.activeTab = "tab1";
      }
    },
    // 获取登录value
    login1(type) {
      if (type == 1) {
        const phoneNumber = this.phoneNumber;
        const password = this.password1;

        console.log("登录手机号:", phoneNumber);
        console.log("登录密码:", password);
        if (phoneNumber === "") {
          alert("请输入手机号");
        } else if (password === "") {
          alert("请输入密码");
        } else {
          this.$http
            .post(
              "index/login",
              {
                mobile: phoneNumber,
                type: "1",
                // code: this.verificationCode,
                user_pass: password,
              },
              {
                headers: {
                  // 'token': localStorage.getItem("token"),
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((response) => {
              console.log("账号登录成功后", response);
              console.log("登录成功后的token", response.data.data.token);
              localStorage.setItem("token", response.data.data.token);

              // 处理成功的响应数据
              if (response.data.data.token) {
                this.$router.push("/index");
              } else {
                alert("账号或密码错误");
              }
            })
            .catch((error) => {
              console.error("登录失败返回", error);
              // 处理错误
            });
        }
      } else {
        const phoneNumber = this.phoneNumber;
        const password = this.password1;
        console.log("登录手机号:", phoneNumber);
        console.log("登录密码:", password);
        if (phoneNumber === "") {
          alert("请输入手机号");
        } else if (this.verificationCode === "") {
          alert("请输入验证码");
        } else {
          var canshu = {
            mobile: phoneNumber,
            type: "2",
            code: this.verificationCode,
            // user_pass: password,
          };
          this.$http
            .post("index/login", canshu, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then((response) => {
              console.log(response.data, "登录成功");
              // 处理成功的响应数据
              console.log("手机登录成功后的token", response.data.data.token);
              localStorage.setItem("token", response.data.data.token);
              if (response.data.data.token) {
                this.$router.push("/index");
              } else {
                alert("账号或者验证码错误");
              }
            })
            .catch((error) => {
              console.error(error);

              // 处理错误
            });
        }
      }
    },
    // 获取注册信息
    register() {
      const phoneNumber = this.phoneNumber;
      const verificationCode = this.verificationCode;
      console.log("注册手机号:", phoneNumber);
      console.log("注册验证码:", verificationCode);
      // 执行注册逻辑
      this.$http
        .post("index/register", {
          mobile: this.phoneNumber,
          code: this.verificationCode,
        })
        .then((response) => {
          console.log("账号注册", response);
          // 处理成功的响应数
          localStorage.setItem("token", response.data.data.token);
          this.$router.push("/index/password");
        })
        .catch((error) => {
          console.error(error);
          // 处理错误
        });
    },
    //input失焦事件

    // 二维码
    fetchData() {
      this.$http
        .get("wechat/qrcode")
        .then((response) => {
          // 处理成功响应
          console.log(response);
          this.img = response.data.data;
        })
        .catch((error) => {
          // 处理错误响应
          console.error(error);
        });
    },

    // 获取验证码
    getCode(type) {
      this.$http
        .post("index/code", {
          mobile: this.phoneNumber,
          type: type + "",
        })
        .then((response) => {
          console.log("获取验证码返回的信息", response.data);
          // 处理成功的响应数据
          if (response.data.message.length == 8) {
            this.verificationCode = response.data.message.slice(-4);
            console.log("this.verificationCode", this.verificationCode);
          }
        })
        .catch((error) => {
          console.error(error);
          // 处理错误
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.login {
  position: absolute;
  width: 100%;
  height: 1080px;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: none; */
}

.loginb {
  margin: 120px auto;
  width: 1240px;
  height: 715px;
  opacity: 1 !important;
  border-radius: 12px;
  display: flex;
  background-image: url("../../img/login.png");
  background-size: cover;
  /* 图像铺满元素的宽高 */
  background-position: center;
  /* 图像在元素中居中显示 */
  position: relative;
  z-index: 9;
}

.loginb-right {
  position: absolute;
  width: 410px;
  right: 104px;
  top: 46px;
}

.tabs {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  padding-left: 50px;
}

.tab.active {
  /* 选中样式 */
  /* 添加你希望的选中样式 */
  position: relative;
}

.tab.active::after {
  position: absolute;
  content: "";
  width: 92px;
  height: 6px;
  background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
  border-radius: 3px;
  top: 37px;
  left: -19px;
}

.tab {
  margin-right: 60px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
}

.tab-content {
  margin-top: 33px;
}

.welogin {
  margin-left: 24px;
  margin-top: 50px;
}

.welogin iframe {
  width: 306px;
  height: 391px;
}

.info {
  width: 0 !important;
  margin: 0 auto;
}

.qrcode {
  width: 149px !important;
}

.welogin p {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}

.tab-pane ul {
  /* margin-left: 15px; */
}

.tab-pane ul li {
  text-align: left;
  margin-top: 20px;
}

.tab-pane ul li p {
  margin-left: 16px;
  color: #4a4a4a;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.in {
  width: 410px;
  height: 69px;
  background: #f7f9ff;
  border: 1px solid #d1dbff;
  outline: none;
  padding-left: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.in p {
  width: 90px;
  height: 25px;
  font-size: 18px !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-left: 196px !important;
  background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#in {
  width: 100px;
  border: none;
  padding: 0;
}

.tab-pane input {
  width: 410px;
  height: 69px;
  background: #f7f9ff;
  border: 1px solid #d1dbff;
  padding-left: 12px;
  outline: none;
  font-size: 28px;
}

.tab-pane button {
  border: none;
  color: #ffffff;
  margin-top: 34px;
  width: 422px;
  height: 69px;
  background: linear-gradient(135deg, #4e6ef0 0%, #6f8bff 100%);
  border-radius: 6px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.bottomul {
  display: flex;
  margin-top: 40px;
}

.bottomul li {
  width: 137px;
  height: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(151, 151, 151, 0),
      rgba(151, 151, 151, 0.5)
    )
    1 1;
}

.bottomul p {
  margin-top: -8px;
  margin-left: 24px;
  font-weight: 400;
  color: #222222;
  width: 96px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}

.loginb-bottom {
  display: flex;
  justify-content: space-evenly;
  margin-top: 14px;
}

.loginb-bottom div img {
  width: 69px;
  height: 69px;
}

.loginb-bottom div p {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
</style>
