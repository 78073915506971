import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import Element from 'element-ui'
Vue.use(Element)
Vue.config.productionTip = false




import axios from 'axios'
// const URL = 'https://wuhan.yvliang.com/api'; // 替换为你的接口基础URL 公司
const URL = 'https://admin.gaiva.cn/api'; // 替换为你的接口基础URL 客户



import dataV from '@jiaminghi/data-view'

Vue.use(dataV)


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')


// 创建axios实例
const instance = axios.create({
	baseURL: URL,
	timeout: 5000, // 请求超时时间
});

Vue.prototype.$http = instance;

// // 添加请求拦截器
instance.interceptors.request.use((config) => {
	// config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
	// instance.defaults.headers.common['token'] = JSON.stringify(localStorage.getItem('token'));
	// instance.defaults.headers.common['token'] = `${localStorage.getItem("token")}`;
	// instance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
	// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

	// if (["/login"].indexOf(config.url) === -1) {
	//   const token = localStorage.getItem("token");
	//   if (token) {
	//     config.headers.Authorization = token;
	//   }
	// }
	// config.data = config.data || {};
	// if (config.method == "get") {
	//   config.params.verificode = getVerificode();
	// } else {
	//   config.data.verificode = getVerificode();
	// }
	return config;
}, (error) => {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use((response) => {
	if (response.data.code == 0) {
		console.log("response登录已过期", response)
		if (response.data.data.status == -2 && localStorage.getItem("token")) {
			alert("登录已过期，或账号在其他地方登录，请重新登录")
			localStorage.removeItem("token");
			if (router.currentRoute.path == '/') {
				location.reload()
			} else {
				router.replace("/");
			}
			return 0;
		}
	}
	if (!localStorage.getItem("token")) {
		if (router.currentRoute.path == '/') {
			// location.reload()
		} else {
			router.replace("/");
		}
	}
	// 对响应数据做点什么
	return response;
}, (error) => {
	// 超出 2xx 范围的状态码都会触发该函数。
	// 对响应错误做点什么
	return Promise.reject(error);
});